// Support component names relative to this directory:
var ujs = require("react_ujs");
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// this fixes react components not loading after a turbo navigation
// mhttps://github.com/reactjs/react-rails/issues/1103
// earlier: var ujs = require("react_ujs");
ujs.handleEvent('turbo:load', ujs.handleMount);
ujs.handleEvent('turbo:before-render', ujs.handleUnmount);